import React, { useEffect, useState } from 'react'
import ExpeditionOverview from '../../../../components/expeditionOverview/Index'
import ModalUserTerm from '../../../../components/ModalUserTerm'
import AppToolBar from '../../components/AppToolBar'
import BarTop from '../../components/BarTop'
import api from '../../../../services/api'

import Draft, { convertFromHTML } from 'draft-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { Popup } from '../../../../components/Popup'

import { QueueWarning } from './components/QueueWarning'
import { DriverGreeting } from './components/DriverGreeting'
import { DriverActions } from './components/DriverActions'
import { PageLoading } from '../../../../components/PageLoading/PageLoading'

import {
  CreateCheckinCheckout,
  driverCheckInServices
} from '../../../../domain'
import { useDriverContext } from '../../../../contexts/DriverContext/useDriverContext'
import { useHistory } from 'react-router-dom'
import './styles.css'
interface Popup {
  id: number
  label: string
  title: string
  content: string
  active: boolean
  start_at: Date
  end_at: Date
  imageUrl: string | null
  company: {
    id: number
  }
}

export interface DriverCheckIn {
  id: number
  driverQueueId: number
  driverCheckInActive: boolean
  ipAuthorized: string
  checkInCreatedAt: string
}

export function DriverHome() {
  library.add(fas)

  const EditorState = Draft.EditorState
  const ContentState = Draft.ContentState

  const {
    driverCheckIn,
    updateCheckin,
    driverId,
    driverCompanyId,
    driverFirstName,
    settingsCompany,
    isLoading,
    closeLoading,
    fetchCheckInActive
  } = useDriverContext()

  const history = useHistory()

  const [delay, setDelay] = useState('')
  const [position, setPosition] = useState<any>(null)
  const [attendance, setAttendance] = useState<any>()
  const [iconName, setIconName] = useState<any>('')
  const [modal, setModal] = useState('')

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(''))
  )
  const [title, setTitle] = useState('')

  const [popupsActive, setPopupsActive] = useState<Popup[]>([])
  const [showPopup, setShowPopup] = useState(false)

  const driverCompanyName = sessionStorage
    .getItem('@AppDriver:companyName')
    ?.replace(/"/g, '')

  function handleClosePopup() {
    setShowPopup(false)

    api.post('/popups/read', {
      id: popupsActive[0].id,
      driver: {
        id: driverId
      },
      company: {
        id: Number(driverCompanyId)
      }
    })
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleOpenModalTerms() {
    showModal()
  }

  const showModal = () => setModal('show')
  const hideModal = () => setModal('')

  function fetchPopupsActives() {
    api
      .get(`/popups/company/active/${Number(driverCompanyId)}`)
      .then(response => {
        setPopupsActive(response.data)
      })
  }

  async function createLogCheckoutOut({ id, driver }: CreateCheckinCheckout) {
    await driverCheckInServices.createLogCheckout({
      id,
      driver
    })
  }

  
  async function cancelCheckIn() {
    if (!driverId || !driverCheckIn?.driverCheckInActive) return

    await driverCheckInServices
      .create({
        id: driverCheckIn.id,
        company: { id: Number(driverCompanyId) },
        queue: { id: driverCheckIn.driverQueueId },
        isPresent: false,
        status: true
      })
      .then(async checkin => {
        updateCheckin(null)

        await createLogCheckoutOut({
          id: driverCheckIn.id,
          driver: { id: Number(driverId) }
        })

        if (checkin) {
          history.push('/app/checkin', { stepId: 4, checkin })
        }
      })
  }

  useEffect(() => {
    fetchCheckInActive()
    fetchPopupsActives()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverCompanyId])

  useEffect(() => {
    if (popupsActive.length > 0) {
      setShowPopup(true)
    }
  }, [popupsActive])

  useEffect(() => {
    api.get(`/useTerm/user/company/${driverCompanyId}`).then(response => {
      if (response.data) {
        setTitle(response.data.tile)

        const blocksFromHTML = convertFromHTML(response.data.content)
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )

        setEditorState(EditorState.createWithContent(state))
      }
    })

    api.get(`/queue/delayStatus/company/${driverCompanyId}`).then(response => {
      if (response?.data?.delay?.id) {
        setDelay(response?.data?.delay?.reason)
        setIconName(response?.data?.delay?.icon)
      }
    })

    api.get(`/settings/operating-data/${driverCompanyId}`).then(response => {
      let start = response.data.settings.start
      let end = response.data.settings.end
      let weekDays = JSON.parse(response.data.settings.weekDays)
      weekDays = Object.values(weekDays)

      let week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

      let selectedDays = week.filter((item: any, index: number) => {
        if (weekDays[index] === true) {
          return week[index]
        }

        return ''
      })

      api
        .get(`/attendances/today/company/${driverCompanyId}`)
        .then(response => {
          setAttendance({
            attendance:
              response.data?.id && response.data?.status ? response.data.id : 0,
            attendanceStart: start,
            attendanceEnd: end,
            days: selectedDays
          })

          localStorage.setItem(
            '@AppDriver:attendance',
            JSON.stringify({
              attendance:
                response.data?.id && response.data?.status
                  ? response.data.id
                  : 0,
              attendanceStart: start,
              attendanceEnd: end,
              days: selectedDays
            })
          )
        })
    })

    api.get(`/queue/company/${driverCompanyId}`).then(response => {
      const index = response.data.findIndex(
        (x: any) => x.driver?.id === Number(driverId)
      )

      if (index >= 0) {
        setPosition(index + 1)
      }
    })
  }, [driverCompanyId, ContentState, EditorState, driverId])

  const driverHasPosition = position !== null
  const showButtonsActions =
    driverHasPosition || settingsCompany?.enableQueueSave

  return (
    <>
      <div className='home-driver'>
        <div className='container'>
          <BarTop />

          <div className='header'>
            <AppToolBar pathToBack='/' isStatic={false} />
          </div>

          <div className='content home-app'>
            {delay && (
              <div className='dealyContainer'>
                <div className='delay-reason'>
                  <div>Atenção, Atendimento em atraso</div>
                  <div>
                    <FontAwesomeIcon icon={['fas', iconName]} /> {delay}
                  </div>
                </div>
              </div>
            )}

            <ExpeditionOverview attendance={attendance} />

            <DriverGreeting
              driverName={driverFirstName}
              companyName={driverCompanyName}
            />

            {isLoading ? (
              <PageLoading loading={isLoading} onCloseLoading={closeLoading} />
            ) : (
              <>
                {showButtonsActions ? (
                  <DriverActions
                    cancelCheckIn={cancelCheckIn}
                    driverCheckIn={driverCheckIn}
                    driverHasPosition={driverHasPosition}
                    onOpenModalTerms={handleOpenModalTerms}
                  />
                ) : (
                  <QueueWarning />
                )}
              </>
            )}
          </div>
        </div>

        {showPopup && (
          <Popup
            title={popupsActive[0].title}
            imageUrl={popupsActive[0].imageUrl!}
            content={popupsActive[0].content}
            onClosePopup={handleClosePopup}
          />
        )}
      </div>

      <ModalUserTerm
        type='Termo'
        id={Number(driverId)}
        title={title}
        className={modal}
        handleClose={handleCloseModal}
        editorState={editorState}
      />
    </>
  )
}
