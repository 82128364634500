import './styles.css'

import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridCellParams, ptBR } from '@mui/x-data-grid'

import api from '../../../../services/api'
import AppToolBar from '../../components/AppToolBar'
import _Cancelamento from '../../../../assets/images/Data_Cancelamento.svg'
import BarTop from '../../components/BarTop'
import { PageLoading } from '../../../../components/PageLoading/PageLoading'
import { useDriverContext } from '../../../../contexts/DriverContext/useDriverContext'
import { Rows } from './driverQueueStatusTypes'
import { isSuccessRequest } from '../../../../utils'
import { format, parseISO } from 'date-fns'
import { ptBR as localePTBR } from 'date-fns/locale'
import { ToleranceTimer } from './components/ToleranceTimer'
import { UpdateQueueWarning } from './components/UpdateQueueWarning'
import { OrderDetails } from './components/Order'
import { WarningActionsBlocked } from './components/WarningActionsBlocked'
import { useDriverGetQueuePosition } from '../../../../domain'

interface LocationState {
  from?: string;
}


export function DriverQueueStatus() {
  const { driverId, driverCompanyId} = useDriverContext()

  const driverQueuePosition = useDriverGetQueuePosition(driverId ?? '')
  const [status, setStatus] = useState(false)
  const [timmer, setTimmer] = useState(1)
  const [reload, setReload] = useState(false)

  const [next, setNext] = useState < any[] > ([])

  const [reschedule, setReschedule] = useState(1)
  const [remove, setRemove] = useState(1)
  const [showOrderDetails, setShowOrderDetails] = useState(false)
  const [toleranceTime, setToleranceTime] = useState < any > ()
  const [toleranceSettings, setToleranceSettings] = useState('00:00')

  const [initialTime, setInitialTime] = useState < any > (0)
  const [finalTime, setFinalTime] = useState < any > (0)
  const [enableTolerance, setEnableTolerance] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const [rows, setRows] = useState < Rows[] > ([])

  const history = useHistory()

  const location = useLocation < LocationState > ();
  const paginaAnterior = location.state?.from || '/';



  useEffect(() => {
    if (driverId) {

      api.get(`/queue/position/driver/${driverId}`).then(response => {
        let object = response.data

        api.get(`/settings/company/${driverCompanyId}`).then(response => {
          let tolerance = response.data.tolerance
          setToleranceSettings(tolerance)
          /***** */

          let toleranceHours = Number(tolerance.slice(0, 2)) * 60 * 60 * 1000
          let toleranceMinutes = Number(tolerance.slice(3, 5)) * 60 * 1000

          let time =
            new Date(object.queue?.date).getTime() +
            (toleranceHours + toleranceMinutes)
          setInitialTime(object?.queue)
          setFinalTime(time)

          let timeleft = Math.round(time - new Date().getTime())

          let days: any = Math.floor(timeleft / (1000 * 60 * 60 * 24))
          let hours: any = Math.floor(
            (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          )
          let minutes: any = Math.floor(
            (timeleft % (1000 * 60 * 60)) / (1000 * 60)
          )
          let seconds: any = Math.floor((timeleft % (1000 * 60)) / 1000)

          setToleranceTime(timeleft)

          if (seconds < 10 && seconds > 0) {
            seconds = '0' + seconds
          }
          if (minutes < 10 && minutes > 0) {
            minutes = '0' + minutes
          }
          if (hours < 10 && hours > 0) {
            hours = '0' + hours
          }
          if (days < 10 && days > 0) {
            days = '0' + days
          }
        })
      })

      api.get(`/queue/company/${driverCompanyId}`).then(response => {
        setNext(response.data)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId])

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (timmer > 0) {
      interval = setInterval(() => {
        setTimmer(prevTimmer => prevTimmer + 1)

        if (driverQueuePosition.data) {
          api
            .get(`/queue/${driverQueuePosition.data.queue.id}`)
            .then(response => {
              setStatus(response.data.status)
            })
            .catch(() => window.location.reload())
        }
      }, 500 * 60) // 30s
    }

    return () => clearInterval(interval)
  }, [driverQueuePosition.data, timmer])

  function handleCancel() {
    setReschedule(1)
    setRemove(1)
  }

  const handleRemove = () => setRemove(0)
  const handleSeePanel = () => history.push('/app/painel/fila')

  const toggleOrderDetails = () => setShowOrderDetails(prevState => !prevState)

  async function handleConfirmRemove() {
    try {
      setDisabled(true)

      if (driverQueuePosition?.data?.queue) {
        const queueId = driverQueuePosition?.data?.queue.id

        const response = await api.delete(`/queue/delete/${queueId}`)

        if (isSuccessRequest(response.status)) {
          setTimeout(() => {
            history.push('/')
          }, 1000)
        }
      }
    } catch (error) {
      setDisabled(false)
    }
  }

  function handleCloseLoading() { }

  useEffect(() => {
    if (driverId) {
      api.get(`/queue/company/${driverCompanyId}`).then(response => {
        const _queue = response.data

        const customPosition = response.data.findIndex((item: any) => {
          if (item.driver?.id === parseInt(driverId)) {
            return item.driver?.id
          } else {
            return ''
          }
        })

        const rows = _queue.map((item: any, index: any) => {
          let logged = false
          let position = index + 1 + '°'

          if (customPosition === index) {
            logged = true
          }

          const licensePlateExists = item.scheduling
            ? item.scheduling.truck?.licensePlate
            : item.truck?.licensePlate

          const vehicleModelExists = item.scheduling?.truck?.vehicleModel?.name
          const isFreelancer = item.scheduling ? false : true

          return {
            id: item.id,
            logged: logged,
            position: position,
            licensePlate: licensePlateExists,
            freelancer: isFreelancer,
            vehicleModel: vehicleModelExists
          }
        })

        setRows(rows)
      })
    }

    api.get(`/settings/company/${driverCompanyId}`).then(response => {
      setEnableTolerance(response.data.enableTolerance)
    })
  }, [driverCompanyId, driverId])

  const columns: GridColDef[] = [
    { field: 'position', headerName: 'Fila', width: 90, sortable: false },
    {
      field: 'licensePlate',
      headerName: 'Placa',
      width: 150,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div className={params.row.logged ? 'logged' : ''}>
              {params.row.licensePlate}
            </div>
          </>
        )
      }
    }
  ]

  let maxTolerance = finalTime + 3 * 3600 * 1000 - new Date().getTime()
  let updateTime =
    new Date(initialTime?.date ? initialTime?.date : null).getTime() -
    new Date().getTime()

  const position = driverQueuePosition?.data?.position
  const queueDate = format(
    parseISO(driverQueuePosition?.data?.queue.date ?? new Date().toISOString()),
    "dd 'de' MMMM 'às' HH:mm",
    {
      locale: localePTBR
    }
  )

  const showToleranceTimer =
    initialTime !== undefined &&
    maxTolerance > 0 &&
    updateTime < 0 &&
    enableTolerance &&
    driverQueuePosition?.data?.queue?.status === false

  const showUpdateQueueWarning =
    !status && !driverQueuePosition?.data?.queueVisibility

    useEffect(() => {
      if (paginaAnterior === '/app/agendamento' && !position && !reload) {
        const timer = setTimeout(() => {
          setReload(true); 
          window.location.reload();
        }, 5000); 

        return () => clearTimeout(timer);
      }
    }, [paginaAnterior, position, reload]);

  return (
    <>
      <div className='queue driver-queue-status'>
        <div className='container'>
          <BarTop />

          <div className='header'>
            <AppToolBar pathToBack='/' isStatic={false} />
          </div>

          {driverQueuePosition.isLoading ? (
            <PageLoading
              loading={driverQueuePosition.isLoading}
              onCloseLoading={handleCloseLoading}
            />
          ) : (
            <>
              {position ||
                driverQueuePosition?.data?.queue?.attended === false ? (
                <>
                  <div className={reschedule ? 'disable' : 'reschedule'}>
                    <div>
                      Deseja <strong>remarcar</strong>
                      <br /> seu agendamento?
                    </div>
                    <div>Mudança de posição na fila</div>
                    <div className='filadados'>
                      <div className='pos'>{position}°</div>

                      <div>Posição atual </div>
                      <div>
                        <strong>Nova</strong> posição{' '}
                      </div>
                    </div>

                    <div className='scheduling'>
                      <div>
                        <span>Previsão de carregamento:</span>
                        <div>
                          A data e hora acima informada trata-se de uma previsão
                          que pode ser antecipada ou adiada sem aviso prévio a
                          depender da dinâmica do fluxo de atendimento na
                          indústria. Recomendamos a consulta constante de sua
                          posição e horário de previsão, e a chegada ao local
                          com 6 horas de antecedência.
                        </div>
                      </div>
                      <span className='date-schedule'>{queueDate}</span>
                      <div className='reagendar-btn'>
                        <button onClick={handleCancel}>Não</button>
                      </div>
                    </div>
                  </div>

                  <div className={remove ? 'disable' : 'remove'}>
                    <div className='fullscreen-popup'>
                      <img src={_Cancelamento} width={'40%'} alt='' />

                      <div>
                        Deseja <strong>cancelar</strong>
                        <br /> a marcação?
                      </div>
                      <div className='cancelar-btn'>
                        <button onClick={handleCancel}>Não</button>
                        <button
                          disabled={disabled}
                          onClick={handleConfirmRemove}
                        >
                          Sim
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='content'>
                    <div className='queue-status'>
                      <div>
                        {!status && position && (
                          <>
                            <span> Sua posição agora é: </span>
                            <strong>{position}°</strong>
                          </>
                        )}
                      </div>
                    </div>

                    {showToleranceTimer && (
                      <ToleranceTimer
                        wholeTime={toleranceTime}
                        initialDate={initialTime}
                        finalDate={finalTime}
                        tolerance={toleranceSettings}
                      />
                    )}

                    {maxTolerance < 0 && updateTime > 0 && (
                      <>
                        {Number(position) >
                          Number(driverQueuePosition?.data?.totals) ? (
                          <>
                            {next.length > 0 && !status && (
                              <div className='plates-container'>
                                {position !== 1 && (
                                  <div
                                    className='flex'
                                  // {...(position < 5 ? 'responsive' : 'dfsdf')}
                                  >
                                    <div className='scheduling-exp painel-page status'>
                                      <div style={{ height: 520 }}>
                                        {Number(position) > 5 ? (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(
                                              Number(position) - 6,
                                              Number(position)
                                            )}
                                            columns={columns}
                                            pageSize={10}
                                            headerHeight={30}
                                            disableColumnMenu
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        ) : (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(0, position)}
                                            columns={columns}
                                            pageSize={10}
                                            headerHeight={30}
                                            disableColumnMenu
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {next.length > 0 && !status && (
                              <div className='plates-container'>
                                {position !== 1 && (
                                  <div
                                    className='flex'
                                  // {...(Number(position) < 5
                                  //   ? 'responsive'
                                  //   : 'dfsdf')}
                                  >
                                    <div className='scheduling-exp painel-page status'>
                                      <div style={{ height: 520 }}>
                                        {Number(position) > 5 ? (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(
                                              Number(position) - 6,
                                              Number(position)
                                            )}
                                            columns={columns}
                                            headerHeight={30}
                                            disableColumnMenu
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        ) : (
                                          <DataGrid
                                            autoHeight={true}
                                            autoPageSize={false}
                                            rows={rows.slice(0, position)}
                                            columns={columns}
                                            headerHeight={30}
                                            disableColumnMenu
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            localeText={
                                              ptBR.components.MuiDataGrid
                                                .defaultProps.localeText
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {driverQueuePosition?.data?.queue && (
                      <div
                        className={`scheduling ${status ? 'inAttendence' : ''}`}
                      >
                        {!status &&
                          driverQueuePosition?.data.queueVisibility && (
                            <>
                              <div>
                                <span>Previsão de carregamento:</span>
                              </div>

                              <div
                                className={`status-license-plate ${updateTime < 0 ? 'tolerance' : ''
                                  }`}
                              >
                                {
                                  driverQueuePosition?.data?.queue?.truck
                                    ?.licensePlate
                                }
                              </div>

                              <span className='date-schedule'>{queueDate}</span>
                            </>
                          )}

                        {status &&
                          driverQueuePosition?.data?.queue?.date !==
                          'NaN undefined' && <div>Em atendimento...</div>}

                        {showUpdateQueueWarning && (
                          <UpdateQueueWarning
                            isTolerance={updateTime < 0}
                            licensePlate={
                              driverQueuePosition?.data?.queue?.truck
                                ?.licensePlate
                            }
                          />
                        )}

                        {driverQueuePosition?.data?.scheduling && (
                          // <div className='show-details'>
                          //   <div
                          //     className='details-button'
                          //     onClick={handleOrderDetails}
                          //   >
                          //     Detalhes do pedido
                          //     {orderDetails ? <CgArrowUpO /> : <CgArrowDownO />}
                          //   </div>

                          //   {orderDetails && (
                          //     <div>
                          //       <div>
                          //         <div>Pedido: {order?.code}</div>
                          //         <div>
                          //           <span>Transportadora:</span>
                          //           <div>{order?.shippingCompany.name}</div>
                          //         </div>
                          //         <div>
                          //           <span>Destinatário:</span>
                          //           {order?.customer.name}
                          //         </div>
                          //         <div>
                          //           <span>Município:</span>
                          //           {order?.customer.city}
                          //         </div>
                          //         <div>
                          //           <span>Produto:</span>
                          //           {
                          //             order?.orderItem.reduce(
                          //               (item: any, index: any) => {
                          //                 return item
                          //               }
                          //             ).product.name
                          //           }
                          //           - {qtyOrdered}t
                          //         </div>
                          //       </div>
                          //     </div>
                          //   )}
                          // </div>

                          <OrderDetails
                            scheduling={driverQueuePosition?.data.scheduling}
                            showDetails={showOrderDetails}
                            onShowDetails={toggleOrderDetails}
                          />
                        )}
                      </div>
                    )}

                    {!status && (
                      <>
                        <div className='warning-attendance'>
                          {driverQueuePosition?.data?.queueVisibility ? (
                            <p>
                              A data e hora acima informada trata-se de uma{' '}
                              <div className='bold'>previsão</div> que pode ser
                              antecipada ou adiada sem aviso prévio a depender
                              da dinâmica do fluxo de atendimento na indústria.
                              Recomendamos a consulta constante de sua posição e
                              horário de previsão, e a chegada ao local com 6
                              horas de antecedência.
                            </p>
                          ) : (
                            <p className='outer'></p>
                          )}
                        </div>

                        {driverQueuePosition.isDriverActionsBlocked && (
                          <WarningActionsBlocked />
                        )}

                        <div
                          className='buttons'
                          style={{
                            justifyContent:
                              driverQueuePosition.isDriverActionsBlocked
                                ? 'center'
                                : undefined
                          }}
                        >
                          {!driverQueuePosition.isDriverActionsBlocked ? (
                            <button onClick={handleRemove}>Sair da fila</button>
                          ) : (
                            <button
                              style={{
                                width: 'auto',
                                opacity: 0,
                                visibility: 'hidden'
                              }}
                            />
                          )}

                          <button
                            onClick={() => {
                              handleSeePanel()
                            }}
                          >
                            Ver fila
                          </button>
                        </div>
                        <div className='buttons panel'></div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='content'>
                    {(paginaAnterior === '/app/agendamento' && !position? <div>Criando posição na fila…</div> : <div> Você não possui marcação na fila</div>)}

                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
