import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DriverCheckInStatus } from './DriverCheckInStatus'
import { DriverCheckIn } from '../DriverHome'
import { useDriverContext } from '../../../../../contexts/DriverContext/useDriverContext'
import { useIPNetwork } from '../../../../../hooks/useIPNetwork'

interface DriverActionsProps {
  cancelCheckIn: () => void
  driverHasPosition: boolean
  driverCheckIn: DriverCheckIn | null
  onOpenModalTerms: () => void
}

export function DriverActions({
  cancelCheckIn,
  driverHasPosition,
  driverCheckIn,
  onOpenModalTerms
}: DriverActionsProps) {
  const history = useHistory()

  const { settingsCompany, isDriverIPAuthorized } = useDriverContext()
  const driverIPNetwork = useIPNetwork()

  useEffect(() => {
    if (driverIPNetwork && driverIPNetwork !== driverCheckIn?.ipAuthorized) {
        cancelCheckIn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverIPNetwork])

  function navigateToCheckin() {
    history.push('/app/checkin')
  }

  return (
    <div className='buttons'>
      {isDriverIPAuthorized &&
        driverCheckIn &&
        driverCheckIn.driverCheckInActive && (
          <DriverCheckInStatus createdAt={driverCheckIn.checkInCreatedAt} />
        )}

      {settingsCompany?.enableCheckin && (
        <>
          {isDriverIPAuthorized && !driverCheckIn && driverHasPosition && (
            <button className='checkin' onClick={navigateToCheckin}>
              Fazer Check-in
            </button>
          )}
        </>
      )}

      {!driverHasPosition ? (
        <Link to='/app/fila'>
          <button>Consultar Fila</button>
        </Link>
      ) : (
        <Link to='/app/agendamento/status'>
          <button>Minha Posição</button>
        </Link>
      )}

      <Link to='/app/como-chegar'>
        <button>Como Chegar?</button>
      </Link>

      <div className='privacy-policy'>
        <button onClick={onOpenModalTerms} className='settings'>
          Política de Privacidade e Termos de Uso{' '}
        </button>
      </div>
    </div>
  )
}
